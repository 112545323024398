import {
  getMileageStepDataFromBooking,
  getServiceStepDataFromBooking,
  getMalfunctionStepDataFromBooking,
  getInspectionStepDataFromBooking,
  getReplacementVehicleStepDataFromBooking,
  getLocationStepDataFromBooking,
  getSelectServiceLocationStepDataFromBooking,
  getContactStepDataFromBooking,
} from './bookingToMnRFormatters'

import type { Booking, Feature, FlowData } from '@ngb-frontend/shared/types'

export const getMnRFlowDataFromBooking = (
  booking: Booking,
): FlowData<Feature.MnR> => {
  return [
    getMileageStepDataFromBooking(booking),
    getServiceStepDataFromBooking(booking, 'services'),
    getMalfunctionStepDataFromBooking(booking),
    getInspectionStepDataFromBooking(booking),
    {}, // Additional information
    getServiceStepDataFromBooking(booking, 'extraServices'),
    getReplacementVehicleStepDataFromBooking(booking),
    { dateTime: booking.service_date_time || '' },
    getLocationStepDataFromBooking(booking),
    {}, // OtherLocation
    getSelectServiceLocationStepDataFromBooking(booking),
    getContactStepDataFromBooking(booking),
  ]
}
