import { BookingServiceCode } from '@ngb-frontend/shared/types'

import type { ServiceOptionValue } from '@ngb-frontend/shared/types'

export const servicesByServiceCode: {
  [key in BookingServiceCode]: ServiceOptionValue[]
} = {
  [BookingServiceCode.MnR]: ['RegularMaintenance', 'Repair', 'MOT'],
  [BookingServiceCode.EoL]: ['EOL'],
}
