import { LocalstorageStore } from '@cj4/store-localstorage'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { useAppConfig } from '@ngb-frontend/shared/context'
import { flatten, useQuery } from '@ngb-frontend/shared/utils'

import type { Store } from '@cj4/store'
import type {
  FlowRouter,
  Steps,
  FlowProps,
  Feature,
  FlowData,
} from '@ngb-frontend/shared/types'

type FlowApi<T extends Feature = Feature.MnR> = FlowProps & {
  hasPreviousStep: boolean
  setFlowData: (flowData: FlowData<T>) => void
}

export const useFlow = <T extends Feature = Feature.MnR>(
  steps: Steps<T>,
  stepName: string,
  router: FlowRouter,
  prefix = '',
  initialFlowData: FlowData<T> = [],
  routingIsDeferred = false,
): FlowApi<T> => {
  const config = useAppConfig()
  const store = useRef<Store<FlowData<T>>>(
    new LocalstorageStore(config.localStorageKeys.flow),
  )
  const [flowData, setFlowData] = useState<FlowData<T>>(
    store.current.get() || initialFlowData,
  )
  const query = useQuery()

  const onSetFlowData = useCallback((data: FlowData<T> = []) => {
    setFlowData(data)
  }, [])

  const onClearFlowData = useCallback(() => {
    setFlowData([])
  }, [])

  const initialFlowDataNotSet = !flowData.length && initialFlowData.length

  useEffect(() => {
    if (initialFlowDataNotSet) {
      setFlowData(initialFlowData)
    }
  }, [initialFlowData, initialFlowDataNotSet])

  useEffect(() => {
    flowData && store.current.set(flowData)
  }, [flowData])

  const activeStep = useMemo(
    () => steps.find((step) => step.path === stepName),
    [stepName, steps],
  )
  const activeStepIdx = activeStep ? steps.indexOf(activeStep) : -1
  const isFirstStep = activeStepIdx === 0
  const { length: stepsCount } = steps
  const getMergedFlowData = useCallback(
    () => flatten(flowData.slice(0, activeStepIdx + 1)),
    [flowData, activeStepIdx],
  )
  const mergedFlowData = getMergedFlowData()
  const goToStepByIndex = useCallback(
    async (stepIndex: number, autoRouting?: boolean) => {
      if (stepIndex >= 0 && stepIndex < stepsCount) {
        setStepDirection(stepIndex > activeStepIdx ? 'fwd' : 'bwd')
        const targetStep = steps[stepIndex].path
        autoRouting
          ? await router.replace(targetStep, undefined)
          : await router.push(targetStep, undefined)
      }
    },
    [router, steps, stepsCount, activeStepIdx],
  )

  /**
   * Checks the steps starting from the given step index and going backwards and returns
   * the first available step index. A step is considered available if it's prerequisites are met.
   */
  const getFirstAvailableStepIndex = useCallback(
    (stepIndex: number) => {
      let availableStepIndex = stepIndex
      let hasUnmetPrerequisites = false
      for (; availableStepIndex >= 0; availableStepIndex--) {
        const { component } = steps[availableStepIndex]
        if (
          component.areStepPrerequisitesMet(
            mergedFlowData,
            // @ts-ignore
            activeStep?.['flowVariant'],
          )
        ) {
          break
        } else {
          hasUnmetPrerequisites = true
        }
      }
      if (hasUnmetPrerequisites) {
        const warning = `[Flow]: Tried to navigate to an unavailable step ${stepIndex}. The first available step is ${availableStepIndex}. Merged flow data: `
        // eslint-disable-next-line no-console
        console.warn(warning, mergedFlowData) // NOSONAR
      }
      return availableStepIndex
    },
    [activeStep, mergedFlowData, steps],
  )

  // Rerouting navigation effect: in case there's not enough data for the active step
  // or flow is not on the first incomplete step, reroute to the first step which has enough data
  useEffect(() => {
    if (activeStepIdx <= 0 || routingIsDeferred || initialFlowDataNotSet) return

    const availableStepIndex = getFirstAvailableStepIndex(activeStepIdx)

    if (
      availableStepIndex !== activeStepIdx ||
      (!flowData[activeStepIdx] && !flowData[activeStepIdx - 1] && !isFirstStep)
    ) {
      goToStepByIndex(Math.min(availableStepIndex, flowData.length), true)
    }
  }, [
    routingIsDeferred,
    activeStepIdx,
    flowData,
    getFirstAvailableStepIndex,
    goToStepByIndex,
    isFirstStep,
    initialFlowDataNotSet,
  ])

  const hasPreviousStep = useMemo(
    () => !!steps[activeStepIdx - 1],
    [activeStepIdx, steps],
  )

  const [stepDirection, setStepDirection] = useState<'fwd' | 'bwd'>('fwd')

  const goToNextStep = useCallback(
    (idx = activeStepIdx + 1) => {
      if (steps[idx]) {
        setStepDirection('fwd')

        return router.push(`${prefix}/${steps[idx].path}`, undefined, {
          shallow: true,
        })
      }
      return Promise.resolve(false)
    },
    [activeStepIdx, prefix, router, steps],
  )

  const goToPreviousStep = useCallback(
    (idx = activeStepIdx - 1) => {
      const step = steps[idx]
      if (step) {
        setStepDirection('bwd')
        return router.push(`${prefix}/${step.path}`, undefined, {
          shallow: true,
        })
      }
      return Promise.resolve(false)
    },
    [activeStepIdx, prefix, router, steps],
  )

  const onSetStepData = useCallback(
    (nextData: FlowData<T>[number]) => {
      const trimmedFlowData = flowData.slice(
        0,
        activeStepIdx + 1,
      ) as FlowData<T>
      const activeStepData = trimmedFlowData[activeStepIdx] || {}
      trimmedFlowData[activeStepIdx] = {
        ...activeStepData,
        ...nextData,
      }
      setFlowData(trimmedFlowData)
    },
    [activeStepIdx, flowData],
  )

  const skipStep = useCallback(
    (nextData: FlowData<T>[number]) => {
      if (stepDirection !== 'fwd') return goToPreviousStep()
      onSetStepData(nextData)
      return goToNextStep()
    },
    [stepDirection, goToPreviousStep, onSetStepData, goToNextStep],
  )

  const component = useMemo(
    () => steps[activeStepIdx]?.component,
    [activeStepIdx, steps],
  )

  const skipPolicy = useMemo(() => {
    const component = steps[activeStepIdx]?.component
    return component?.skip?.(mergedFlowData)
  }, [activeStepIdx, mergedFlowData, steps])

  const stepVariant = useMemo(
    () => component?.getVariant?.(mergedFlowData, query),
    [component, mergedFlowData, query],
  )

  // skipping effect, ensure that it runs after reroute effect
  useEffect(() => {
    if (skipPolicy?.enabled) {
      skipStep(skipPolicy?.state)
    }
  }, [skipPolicy?.enabled, skipPolicy?.state, skipStep])

  return {
    stepIndex: activeStepIdx,
    stepVariant,
    // TODO: fallback to first step as active step
    hasPreviousStep,
    onPreviousStep: goToPreviousStep,
    onNextStep: goToNextStep,
    onUpdateStep: onSetStepData,
    goToStep: goToStepByIndex,
    stepData: flowData[activeStepIdx] || [],
    mergedData: flatten(flowData),
    onClearFlowData,
    skipEnabled: skipPolicy?.enabled,
    setFlowData: onSetFlowData,
  }
}
