import {
  type Address,
  type Booking,
  type Feature,
  type FlowData,
} from '@ngb-frontend/shared/types'

import { getContactStepDataFromBooking } from './bookingToMnRFormatters'
import { groupMalfunctionCodes } from '../object/malfunctions'
import { getRPVTypeFromBooking } from '../replacementVehicle/getRPVTypeFromBooking'

export const getMnRDCCFlowDataFromBooking = (
  booking: Booking,
): FlowData<Feature.MnRDCC> => {
  return [
    {
      mileage: booking.mileage || 0,
      ...getContactStepDataFromBooking(booking),
      services: (booking.service?.items || []).map((s) => s.code),
      extraServices: (booking.additional_services || []).map((s) => s.code),
      notes: '',
      inspection: booking.mot_type,
      malfunctions: groupMalfunctionCodes(booking.malfunctions),
      malfunctionDescription: booking.malfunction_description,
      dateTime: booking.service_date_time || '',
      replacementVehicle: {
        type: {
          ...getRPVTypeFromBooking(booking),
        },
        address:
          booking.replacement_vehicle_data?.return_address ||
          (booking.driver.pickup_address as Address),
        location: '',
      },
      address: booking.driver.pickup_address as Address,
      location: '',
    },
    {
      serviceTypes: [],
      dateTime: booking.service_date_time || '',
      supplier: booking.supplier,
    },
  ]
}
