import {
  RepossessionType,
  type Address,
  type Booking,
  type Feature,
  type FlowData,
  type TimeSlot,
} from '@ngb-frontend/shared/types'

import { getContactStepDataFromBooking } from './bookingToMnRFormatters'

export const getEoLDCCFlowDataFromBooking = (
  booking: Booking,
): FlowData<Feature.EoLDCC> => {
  return [
    {
      mileage: booking.mileage || 0,
      driverDeceased: !!booking.vehicle_return_data?.customer_deceased,
      ...getContactStepDataFromBooking(booking),
      vehicleDrivable: !!booking.vehicle_return_data?.drivable,
      vehicleNotDrivableReason:
        booking.vehicle_return_data?.nondrivable_reason || '',
      repossession:
        booking.vehicle_return_data?.repossession_type || RepossessionType.No,
      additionalInformation: booking.service?.description || '',
      timeSlot: booking.service_slot as TimeSlot,
      dateTime: booking.service_date_time?.split('T')[0] || '',
      address: {
        ...(booking.driver.pickup_address || {}),
        county:
          booking.driver.pickup_address?.county ||
          booking.vehicle_return_data?.county,
      } as Address,
      services: ['EOL'],
    },
    {
      serviceTypes: [],
      timeSlot: booking.service_slot as TimeSlot,
      dateTime: booking.service_date_time || '',
      supplier: booking.supplier,
    },
  ]
}
