/**
 * Flatten data so it becomes a simple object that we can easily type & use
 *
 * @param data: [0: {data1: 'value1'}, 1: {data2: 'value2'}]
 * @returns flatData: {data1: 'value1', data2: 'value2'}
 */
export const flatten = (
  data: ReadonlyArray<Record<string, unknown> | unknown[]>,
) => {
  return !Array.isArray(data)
    ? {}
    : data.reduce((acc, stepData) => ({ ...acc, ...stepData }), {})
}
