import type {
  BasicFeatureStep,
  DynamicFeatureStep,
  Feature,
  StepVariants,
} from '@ngb-frontend/shared/types'

export const isDynamicStep = <T extends Feature>(
  x: BasicFeatureStep<T> | DynamicFeatureStep<T>,
  variant: StepVariants,
): x is DynamicFeatureStep<T> => {
  // @ts-ignore
  return !!x?.['variant']?.[variant]
}
